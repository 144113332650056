<template>
  <div class="container">
    <div class="nac">
       <van-icon  @click="goback" size=".35rem" name="arrow-left"/>
      <div class="xxm">完善简历</div>
    </div>
    <div class="identity">
      <p class="tian">填写简历信息</p>

      <div class="buddha">
        <span class="sr ss"  @click="show = true">求职状态</span>
        <input
        placeholder="请选择求职状态"
          readonly="readonly"
          class="lian xx"
          id="phone"
          type="text"
          style="text-align: right"
          v-model="statusseeking"
          @click="show1()"
        />
      </div>

      <van-action-sheet v-model="show">
        <div class="content">
          <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="onCancel"
          />
        </div>
      </van-action-sheet>

      <p class="buddha cy">
        <span class="sr ss" @click="$router.push('/intention')">意向行业</span>
        <input
          readonly="readonly"
          v-if="industry.length == 0"
          @click="$router.push('/intention')"
          class="lian"
          type="text"
          placeholder="请选择行业"
          style="text-align: right"
        />
        <span
         v-else
          class="rigth"
          @click="goIntention()"
          >{{industry}}</span
        >
        
      </p>

      <p class="buddha">
        <span class="sr ss" @click="$router.push('/station')">求职岗位</span>
        <input
          readonly="readonly"
          class="lian"
          type="text"
          v-model="job"
          placeholder="请选择职业岗位"
          style="text-align: right"
          @click="$router.push('/station')"
        />
      </p>

    </div>
    <div>
    <p class="btn1" @click="check" id="triggerBtn">下一步</p>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
// import evenBus from "../../util/evenBus"
export default {
  data() {
    return {
      columns: [
        "离职-随时到岗",
        "在职-月内到岗",
        "在职-考虑机会",
        "在职-暂不考虑",
      ],

      show: false,
      statusseeking: "", // 求职状态
      job: "", // 求职岗位
      industry:"", //意向行业
      canremove:true //是否删除缓存industry
    };
  },
  mounted(){
   if(sessionStorage.getItem("resumeType") == 0){
      if( sessionStorage.getItem("industry") != null){
        let industry = ( sessionStorage.getItem("industry")); //意向行业 
        this.industry = industry
      }
      let station = sessionStorage.getItem("position")
      let statusseeking =  sessionStorage.getItem("job_status")
      // console.log(statusseeking)
      this.statusseeking = statusseeking == 1 ? "离职-随时到岗" : (statusseeking == 2 ? "在职-月内到岗" : (statusseeking == 3 ? "在职-考虑机会" : "在职-暂不考虑"));
      this.job = station
      // sessionStorage.setItem("job_status",this.statusseeking == "离职-随时到岗" ? "1" :(this.statusseeking == "在职-月内到岗" ? "2" : (this.statusseeking == "在职-考虑机会" ? "3" : "4")))
      sessionStorage.setItem("jop",this.job)
   }else{
     let perResume = JSON.parse(sessionStorage.getItem("perResume"))
      // console.log(perResume)
      //求职状态
      let a = sessionStorage.getItem("job_status")
      this.statusseeking = a ||  perResume.job_status == 1 ? "离职-随时到岗" : (perResume.job_status == 2 ? "在职-月内到岗" : (perResume.job_status == 3 ? "在职-考虑机会" : "在职-暂不考虑"));
      sessionStorage.setItem("job_status", this.statusseeking == "离职-随时到岗" ? "1" :(this.statusseeking == "在职-月内到岗" ? "2" : (this.statusseeking == "在职-考虑机会" ? "3" : "4")))
      // 意向行业
      var industry;
      if(sessionStorage.getItem("industry") != null){
        industry = (sessionStorage.getItem("industry"))
      }else{
        industry = perResume.industry
      }
      // console.log(industry)
      this.industry =  industry;
      sessionStorage.setItem("industry",industry);
      // 求职岗位
      this.job = sessionStorage.getItem("position") || perResume.jop
      sessionStorage.setItem("jop",this.job)

   }
  },
  methods: {
    show1(){
      this.show = true
    },
    goIntention(){
      this.$router.push({path:'/intention'})
    },
    goback(){
      this.$router.push("./editInfo")
    }, 
    //确定选择求职状态
    onConfirm(value) {
    this.statusseeking = value
    sessionStorage.setItem("job_status", value == "离职-随时到岗" ? "1" :(value == "在职-月内到岗" ? "2" : (value == "在职-考虑机会" ? "3" : "4")) );
    this.show = false;
    },
    //取消选择求职状态
    onCancel() {
      this.show = false;
    },
    //保存内容进入下一步
    check() {
      if(this.statusseeking == ''){
        Toast("请选择求职状态")
        return
      }
      if(this.industry.length == 0){
         Toast("请选择意向行业")
        return
      }
      if(this.job == null){
        Toast("请选择求职岗位")
        return
      }

      this.$router.push("./resume1")
      this.canremove = false;
   },
  },
  destroyed(){
    if(this.canremove){
      sessionStorage.removeItem("industry");
    }
  }
};
</script>

<style scoped>
 .xxm {
        position: absolute;
        margin-left: 50%;
        transform: translateX(-50%);
    }

    .nac > :first-child {
        margin-left: 0.32rem;
    }

    .nac {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #ffffff;
        font-size: 0.34rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.48rem;
        padding: 0.3rem 0;
        position: relative;
        border-bottom-color: #ebedf0;
        border-bottom-style: solid;
        border-bottom-width: 1px;
    }
.container{
   
    position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;

}
.msg {
  height: 0.88rem;
  line-height: 0.88rem;
  line-height: 0.48rem;
  border-bottom: solid 0.01rem #eeeeee;
}
input {
  width: 5rem;
  height:0.6rem;
}
.ss::before {
  content: "*";
  color: #ff0000;
  font-size: 0.25rem;
  position: relative;
  margin-right: 0.1rem;
  /* top: 0.1rem; */
}
.msg span {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
}
.rigth {
  float: right;
  width: 4rem;
  white-space: nowrap;
  overflow: auto;
}
.msg .xia {
  float: right;
  margin-top: 0.24rem;
  margin-right: 0.3rem;
}

.msg .wan {
  margin-right: 0.3rem;
  margin-left: 2.6rem;
}

.tian {
  font-size: 0.45rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #616b80;
  margin-top: 0.38rem;
  margin-bottom: 0.38rem;
}

.identity {
  margin: 0rem 0.32rem;
}

.identity .buddha {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 1.46rem; */
  border-bottom: solid 0.01rem #eee;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #697386;
  padding-bottom:0.3rem;
  margin-bottom:0.2rem;
  /* line-height: 1.46rem; */
}

.identity .buddha .lian {
  border: none;
}

.btn1 {
  width: 6.86rem;
  height: 0.96rem;
  background: linear-gradient(90deg, #f55613 0%, #fc9e46 100%);
  border-radius: 0.48rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 0.96rem;
  margin-top: 1.38rem !important;
  margin-left: 0.34rem;
  margin-bottom: 0.78rem;
}

.buddha textarea {
  float: right;
  width: 3.5rem;
  height: 1rem;
  margin-top: 0.2rem;
  margin-left: 0.1rem;
  border: none;
}
</style>
